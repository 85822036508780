<template>
<div>
  <v-row>
    <v-col md="5">
      <v-btn
        color="primary"
        outlined
        title="Добавить сотрудников, которые будут наблюдать за заявкой"
        @click="addObserver"
      >
        <v-icon class="mr-2">mdi-account-multiple-plus-outline</v-icon>
        Добавить наблюдателей
      </v-btn>
    </v-col>
    <v-col>
      <template
        v-if="observers.length"
      >
        <v-chip
          v-for="observer of observers"
          :key="observer.id"
          class="ma-1"
          close
          @click:close="removeObserver(observer.id)"
        >
          <v-avatar left>
            <img :src="observer.photo" alt="">
          </v-avatar>
          {{observer.name}}
        </v-chip>
      </template>
    </v-col>
  </v-row>
</div>
</template>

<script>
import Bitrix from '@/plugins/Bitrix'

export default {
  name: 'AddObservers',
  model: {
    prop: 'value',
    event: 'observersChange'
  },
    props: {
    value: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    observers: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('observersChange', newValue)
        return newValue
      }
    }
  },
  methods: {
    async addObserver() {
      const users = await Bitrix.selectUsers()
      if (users[0]) {
        this.observers = this.observers.concat(users)
      }
    },
    removeObserver(id) {
      this.observers = this.observers.filter(observer => observer.id !== id)
    }
  }
}
</script>
