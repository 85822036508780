<template>
  <div>
    <v-snackbar
      v-model="successSnackbar"
      :timeout="4000"
      top
      transition="scroll-y-transition"
      color="success"
    >
      Заявка на доставку документов № {{result.elementId}} успешно создана!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="successSnackbar = false"
        >
          ОК
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      top
      :timeout="-1"
      color="error"
    >
      Ошибка ({{result}}) обратитесь к администратору!!!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="errorSnackbar = false"
        >
          ОК
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      color="error"
      v-model="isValidationError"
      top
      right
    >
      Ошибка валидации формы, проверьте поля
    </v-snackbar>
    <div class="wrapper-form">
      <form @submit.prevent>
        <p class="title black--text">Данные отправителя</p>
        <div class="wrapper-block">
          <company-input-sync
            v-model="senderCompany"
            label="Компания отправитель"
            :error-messages="requiredError($v.senderCompany.title, 'Укажите компанию отправителя')"
            :blur="$v.senderCompany.title.$touch"
          />
        </div>
        <p class="title black--text">Данные получателя</p>
        <div class="wrapper-block">
          <company-input-sync
            v-model="recipient.companyInfo"
            label="Компания получатель"
            :error-messages="requiredError($v.recipient.companyInfo.title, 'Укажите компанию получателя')"
            :blur="$v.recipient.companyInfo.title.$touch"
          />
          <v-text-field
            v-model="recipient.address"
            label="Адрес получателя"
            outlined
            dense
            prepend-icon="mdi-map-marker"
            :error-messages="requiredError($v.recipient.address, 'Укажите адрес получателя')"
            @blur="$v.recipient.address.$touch"
          ></v-text-field>
          <contact-input-sync
            :company-id="recipient.companyInfo.id"
            :contact-name="recipient.contactName"
            :contact-phone="recipient.phoneNumber"
            @contact-changed="updateRecipientName"
            @phone-changed="updateRecipientPhone"
            :contact-name-errors="requiredError($v.recipient.contactName, 'Укажите контакт получателя')"
            :contact-phone-errors="requiredError($v.recipient.phoneNumber, 'Укажите телефон получателя')"
          />
        </div>
        <p class="title black--text">Информация по доставке</p>
        <div class="wrapper-block">
          <v-radio-group
            v-model="atWhoseExpenseSelect"
            mandatory
            column
          >
            <template v-slot:label>
              <div>Отправка за счет</div>
            </template>
            <v-radio
              v-for="n in atWhoseExpenseItems"
              :key="n.value"
              :label="n.label"
              :value="n.value"
            >
            </v-radio>
          </v-radio-group>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateText"
                    outlined
                    dense
                    label="Дата передачи в доставку"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :min="new Date().toISOString().substr(0, 10)"
                  locale="ru"
                  first-day-of-week="1"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-select
            v-model="deliverySelect"
            :items="deliveryItems"
            label="Сервис доставки"
            prepend-icon="mdi-moped"
            outlined
            dense
          ></v-select>
          <v-textarea
            v-model.trim="deliveryComment"
            label="Комментарий к доставке"
            rows="3"
            auto-grow
            outlined
            dense
            :error-messages="requiredError($v.deliveryComment, 'Укажите описание доставки')"
            @blur="$v.deliveryComment.$touch"
          ></v-textarea>
          <v-file-input
            v-model="files"
            multiple
            id="test_file_input"
            outlined
            dense
            label="Документы (необязательно)"
            counter
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </div>
        <p class="title black--text">Дополнительная информация</p>
        <div class="wrapper-block">
          <DealInputSync
            v-model="deal"
            title="Сделка (опционально)"
          />
          <v-text-field
            v-model="buyerOrder"
            label="Заказ покупателя"
            outlined
            dense
            ref="buyerOrder"
            :error-messages="requiredError($v.buyerOrder, 'Укажите заказ покупателя')"
            @blur="$v.buyerOrder.$touch()"
          ></v-text-field>
          <ProjectInput v-model="project"/>
          <task-input v-model="taskId"/>
          <add-observers v-model="observers"/>
        </div>
        <v-btn
          class="mr-4 mb-4"
          color="primary"
          @click="submit"
          type="submit"
          :loading="loading"
        >
          Оставить заявку
        </v-btn>
      </form>
    </div>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import ProjectInput from './app/ProjectInput'
  import DealInputSync from '@/components/DealInputSync'
  import CompanyInputSync from '@/components/CompanyInputSync'
  import ContactInputSync from '@/components/ContactInputSync'
  import TaskInput from '@/components/app/TaskInput'
  import AddObservers from '@/components/app/AddObservers'
  import { trimCoordinates } from '@/libs/main'

  export default {
    name: 'SendingDocumentsForm',
    components: {
      AddObservers,
      TaskInput,
      ContactInputSync,
      CompanyInputSync,
      DealInputSync,
      ProjectInput
    },
    validations: {
      senderCompany: {
        title: { required }
      },
      recipient: {
        companyInfo: {
          title: { required }
        },
        contactName: { required },
        phoneNumber: { required },
        address: { required }
      },
      buyerOrder: { required },
      deliveryComment: { required }
    },

    data: () => ({
      senderCompany: {
        id: '581',
        title: 'ООО "ПОЛИСЕРВИС"',
        address: 'Москва, ул. Большая Черемушкинская дом 25, стр. 97, офис 6,7,8',
        logoURL: 'https://polyservice.bitrix24.ru/bitrix/components/bitrix/crm.company.show/show_file.php?ownerId=581&fieldName=LOGO&dynamic=N&fileId=144107'
      },

      recipient: {
        companyInfo: {
          id: '',
          title: '',
          address: '',
          logoURL: ''
        },
        contactName: '',
        phoneNumber: '',
        address: ''
      },

      atWhoseExpenseSelect: 813,
      atWhoseExpenseItems: [
        {
          value: 813,
          label: 'отправителя (ООО "ПОЛИСЕРВИС")'
        },
        {
          value: 815,
          label: 'получателя'
        },
        {
          value: 921,
          label: 'третьего лица'
        }
      ],

      date: new Date().toISOString().substr(0, 10),
      menu: false,

      deliverySelect: 739,
      deliveryItems: [
        {
          value: 731,
          text: 'Dostavista'
        },
        {
          value: 1023,
          text: 'PonyExpress'
        },
        {
          value: 1025,
          text: 'Байкал Сервис'
        },
        {
          value: 1021,
          text: 'Деловые Линии'
        },
        {
          value: 737,
          text: 'Курьер Полимедиа'
        },
        {
          value: 735,
          text: 'Почта России'
        },
        {
          value: 729,
          text: 'СДЭК'
        },
        {
          value: 733,
          text: 'Яндекс.Такси'
        },
        {
          value: 739,
          text: '--Любой--'
        },
        {
          value: 741,
          text: '--Иной--'
        }
      ],

      deliveryComment: '',
      files: [],

      deal: {
        id: '',
        title: '',
        buyerOrder: ''
      },
      buyerOrder: '',
      project: null,
      taskId: '',
      observers: [],

      result: '',
      isValidationError: false,
      loading: false,
      successSnackbar: false,
      errorSnackbar: false
    }),
    computed: {
      ...mapGetters(['getTask', 'DEALS']),
      dateText () {
        return this.date.split('-').reverse().join('-')
      }
    },
    watch: {
      'senderCompany.title'(val) {
        val
          ? this.atWhoseExpenseItems[0].label = `отправителя (${val})`
          : this.atWhoseExpenseItems[0].label = 'отправителя'
      },
      'recipient.companyInfo.title'(val) {
        val
          ? this.atWhoseExpenseItems[1].label = `получателя (${val})`
          : this.atWhoseExpenseItems[1].label = 'получателя'
      },
      'recipient.companyInfo.address'(val) {
        val && (this.recipient.address = trimCoordinates(val))
      },
      'deal.buyerOrder'(val) {
        this.buyerOrder = val
      }
    },
    methods: {
      ...mapActions(['addSendDocsRequest']),
      requiredError(field, text) {
        const errors = []
        if (!field.$dirty) return errors
        !field.required && errors.push(text)
        return errors
      },
      updateRecipientName(data) {
        this.recipient.contactName = data
      },
      updateRecipientPhone(data) {
        this.recipient.phoneNumber = data
      },
      async submit () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.isValidationError = true
          return false
        } else {
          this.$v.$reset()
          this.loading = true
          const formData = {
            sender: this.senderCompany,
            recipient: this.recipient,
            // recipientCompany: this.recipientCompany.title || this.recipientCompany,
            // recipientCompanyAddress: this.recipientCompanyAddress,
            // recipientContactName: this.recipientPerson,
            // recipientContactPhones: this.recipientPersonPhone,
            atWhoseExpenseSelect: this.atWhoseExpenseSelect,
            delivery: this.deliverySelect,
            date: this.date,
            buyerOrder: this.buyerOrder,
            deliveryComment: this.deliveryComment,
            files: this.files,
            deal: this.deal,
            taskId: this.taskId,
            observers: this.observers
          }
          const result = await this.addSendDocsRequest(formData)
          this.loading = false
          this.result = result
          if (result.elementId) {
            this.successSnackbar = true
            this.deliveryComment = this.recipient.contactName = this.recipient.phoneNumber = ''
            this.files = this.observers = []
          } else {
            this.errorSnackbar = true
          }
        }
      }
    }
  }
</script>
